import React, { Component } from 'react';

import './stylesheet.scss';

export default class Footer extends Component {


    render() {
        return (
            <div className="Footer">
                Copyright © 2020 Melle Sieswerda. All rights reserved.
            </div>
        );
    }
}