import React, { Component } from 'react';

import './stylesheet.scss';

export default class About extends Component {

    render() {
        return (
            <div className="About">
                This is the about component.
            </div>
        );
    }
}